import api from '@/ts/api';
import { Dict } from '@/ts/dict';
import tools from '@/ts/tools';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const Store = defineStore('store', {
  state: () => ({         
    profile: useStorage('stima_profile', {}),
    assets: useStorage('stima_assets', []),
    
    chart: useStorage('stima_chart', {}),    
    types: useStorage('stima_types', {}), //asset value by category
    roots: useStorage('stima_roots', {}), //asset value by category
    stats: useStorage('stima_stats', {}),

    pages: useStorage('stima_assets_cache', {}),//asset page cache

    news: useStorage('stima_news', []),
    featured: useStorage('stima_feat', []),
  }),
  
  getters: {

    myAssets:(state) =>{
       let list = state.assets
       console.log("myAssets", list)
       list.sort((a,b) => b.created > a.created);
       for (let x = 0; x < list.length; x++) {
         const item = list[x];
         list[x].link = tools.sids(item.id, item.brand.name, item.brand.modelname, item.title)
       }
    },

    god: (state) => state.profile.god,
    
    menu: (state) => {
      if (state.profile.id) return {}
      let list = {} 
      for (let index = 0; index < state.profile.access.length; index++) {
          const item = state.profile.access[index];
          if (!item) continue
          let p = item.split('/')
          if (p.length < 2) continue
          list[p[0]] = true
      }
      return list
    },

    changeYear: (state) => {
      if (!state.chart) return 0
      if (!state.chart.change) return 0
      if (!state.chart.change.y) return 0      
      return state.chart.change.y.toFixed(2) || 0},
    changeM6: (state) => {
      if (!state.chart) return 0
      if (!state.chart.change) return 0
      if (!state.chart.change.m6) return 0
      return state.chart.change.m6.toFixed(2) || 0
   },
    changeM3: (state) => {
      if (!state.chart) return 0
      if (!state.chart.change) return 0
      if (!state.chart.change.m3) return 0
      return state.chart.change.m3.toFixed(2) || 0
   },
    changeM1: (state) => {
      if (!state.chart) return 0
      if (!state.chart.change) return 0
      if (!state.chart.change.m1) return 0
      return state.chart.change.m1.toFixed(2) || 0
   },
    changeWeek: (state) => {
      if (!state.chart) return 0
      if (!state.chart.change) return 0
      if (!state.chart.change.w) return 0
      return state.chart.change.w.toFixed(2) || 0
   },
    changeDay: (state) => {
      if (!state.chart) return 0
      if (!state.chart.change) return 0
      if (!state.chart.change.d) return 0
      return state.chart.change.d.toFixed(2) || 0
   },
    
    totalStima: (state) => {
      if (!state.chart) return 0
      if (!state.chart.total) return 0      
      return Math.round( state.chart.total * 0.9)  || 0
   },    
    totalUSD: (state) => {
      if (!state.chart) return 0
      if (!state.chart.total) return 0
      return state.chart.total || 0
   },    
   
    valuesByCategory: (state) => state.types || {}, //root id = usd
    
    pointsAll: (state) => {
      if (!state.chart) return []
      if (!state.chart.points) return []
      if (!state.chart.points.all) return []
      let res = state.chart.points.all
      // state.chart.points.all.forEach(point => {
      //    let p = point
      //    let date = new Date(0)
      //    date.setUTCSeconds(p.unix);
      //    p.date = date
      //    res.push(point) 
      // });
      return res      
   },
    pointsYear: (state) => {
      if (!state.chart) return []
      if (!state.chart.points) return []
      if (!state.chart.points.y) return []
      let res = []
      state.chart.points.y.forEach(point => {
         let p = point
         let date = new Date(0)
         date.setUTCSeconds(p.unix);
         p.date = date
         res.push(point) 
      });
      return res      
   },

      
  },
  actions: {
    
    async updateStats(){
      let res = await api.send('stats/global',{})
      if (res.error){
       console.error("empty res")
       return res
      }
      this.stats = res.resp
      return {}
   },
    
    
    async loginGoogle(token){
       let res = await api.send('account/login/google',{token})
       if (res.error){
        console.error("empty res")
        return res
       }
       this.profile = res.resp.profile
       return {}
    },
    
    async loginApple(token){
       let res = await api.send('account/login/apple',{token})
       if (res.error){
        console.error("empty res")
        return res
       }
       this.profile = res.resp.profile
       return {}
    },
    
    async loginEmail(email,pass){
       let res = await api.send('account/login/email',{email,pass})
       if (res.error) return
       console.log(res)
       this.profile = res.resp.profile
       
       return {}
    },
    
    
    async refreshAssets(){
       let res = await api.send('assets/chart',{})
       if (res.error){
        console.error("empty res")
        return res
       }
       this.assets = res.resp.list
       this.chart = res.resp.chart       
       this.types = res.resp.types
       this.roots = res.resp.roots //rootID = chart{}

       console.log('got assets',this.assets)
       console.log('got chart',this.chart)
       console.log('got types',this.types)
       console.log('got roots',this.roots)
       return {}
    },
    
    async refreshNews(){
       let res = await api.send('news/list',{})
       if (res.error) return
       this.news = res.resp
       return {}
    },
    
    async refreshFeat(){
       let res = await api.send('assets/feat',{})
       if (res.error) return
       this.featured = res.resp
       return {}
    },
   
   
    
    async refreshProfile(){
       let res = await api.send('account/me',{})
       if (res.error){
        console.error("empty res")
        return res
       }
       this.profile = res.resp.profile
       return {}
    },
    
    
    async logout(token){
       let res = await api.send('account/logout')
       if (res.error){
        console.error("empty res")
        return res
       }       
       this.profile = {}
       this.stats = {}
       const dict = Dict()
       dict.logout()
       window.location = "/"
       return {}
    },
    
    
      today(){
         var options = {year: 'numeric', month: 'long', day: 'numeric' };
         var today  = new Date();
         //var language = window.navigator.userLanguage || window.navigator.language;
         console.log(window.navigator.userLanguage || window.navigator.language)
         return today.toLocaleDateString("en-US", options)
       
       }
    

    

  },
})

