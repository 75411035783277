import { Store } from '@/ts/store';
import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  // {path: '/welcome',  name: 'welcome',  component: () => import('@/page/welcome.vue')},
  
  {path: '/',         name: 'home',    component: () => import('@/page/index.vue')},  
  // {path: '/assets',   name: 'assets',   beforeEnter: [logged], component: () => import('@/page/assets.vue')},
  {path: '/profile',  name: 'profile',  beforeEnter: [logged], component: () => import('@/page/profile.vue')},
  {path: '/profile/settings',  name: 'profile/settings',  beforeEnter: [logged], component: () => import('@/page/profile/settings.vue')},
  
  {path: '/planet',  name: 'planet',  beforeEnter: [logged], component: () => import('@/page/planet_old.vue')},
  
  
  {path: '/team',  name: 'team',  beforeEnter: [logged,god], component: () => import('@/page/team/index.vue'),
   
  },
  
   // accounts
   {path: '/accounts',      name:'accounts', beforeEnter: [logged], component: () => import('@/page/accounts/index.vue'),
    // children: [        
    //     {path: ':id',     name: 'accounts/page',    beforeEnter: [logged],  component: () => import('@/page/accounts/page/index.vue'),
    //       children: [
    //         {path: 'assets',  name: 'accounts/page/assets',  beforeEnter: [logged],  component: () => import('@/page/accounts/page/assets.vue')},
    //         {path: 'support',  name: 'accounts/page/support',  beforeEnter: [logged],  component: () => import('@/page/accounts/page/support.vue')},
    //         {path: 'history',  name: 'accounts/page/history',  beforeEnter: [logged],  component: () => import('@/page/accounts/page/history.vue')},
    //       ]
    //     },
    //     // {path: 'search',  name: 'accounts/search',  beforeEnter: [logged],  component: () => import('@/page/accounts/search.vue')},
    //     // {path: 'new',     name: 'accounts/create',  beforeEnter: [logged],  component: () => import('@/page/accounts/create.vue')},        
    // ]
  },

  {path: '/assets/create',  name: 'assets/create',  beforeEnter: [logged], component: () => import('@/page/assets/create.vue')},
  {path: '/asset/:id',  name: 'asset/page',  beforeEnter: [logged], component: () => import('@/page/asset/index.vue')},
  {path: '/assets',  name: 'assets',  component: () => import('@/page/assets/index.vue')},


  {path: '/about/:id',  name: 'doc/page',  beforeEnter: [logged], component: () => import('@/page/doc/page.vue')},
   // assets
  //  {path: '/assets', name:'assets', beforeEnter: [logged], component: () => import('@/page/assets/index.vue'),
  //   children: [
      
  //     // {path: ':id',  name: 'assets/search',  beforeEnter: [logged],  component: () => import('@/page/assets/page.vue')},        
      
  //     // {path: ':category',   name: 'assets/category',  beforeEnter: [logged],  component: () => import('@/page/assets/category.vue')},  
      
  //   ]
  // },
   
  
  // news
   {path: '/news',      name:'news', beforeEnter: [logged], component: () => import('@/page/news/index.vue'),
    children: [        
      
      {path: ':id',  name: 'news/page',  beforeEnter: [logged],  component: () => import('@/page/news/page.vue')},  
      // {path: ':category',   name: 'assets/category',  beforeEnter: [logged],  component: () => import('@/page/assets/category.vue')},  
      
    ]
  },

  // assets category
  // {path: '/:category',  name: 'assets/category',    beforeEnter: [logged],  component: () => import('@/page/assets/page/index.vue'),
  //     children: [
  //       {path: 'assets',    name: 'category/assets',  beforeEnter: [logged],  component: () => import('@/page/assets/page/assets.vue')},
  //       // {path: 'mode',      name: 'category/mode',    beforeEnter: [logged],  component: () => import('@/page/assets/page/mode.vue')},
  //       // {path: 'brands',    name: 'category/brands',  beforeEnter: [logged],  component: () => import('@/page/assets/page/brands.vue')},
  //       {path: ':name',     name: 'category/dict',    beforeEnter: [logged],  component: () => import('@/page/assets/page/dict.vue')},
  //       // {path: 'fields',    name: 'category/fields',  beforeEnter: [logged],  component: () => import('@/page/assets/page/fields.vue')},
  //       {path: 'settings',  name: 'category/settings',    beforeEnter: [logged],  component: () => import('@/page/assets/page/settings.vue')},
  //       // {path: 'owners',    name: 'category/owners',  beforeEnter: [logged],  component: () => import('@/page/assets/page/owners.vue')},
  //       // {path: 'history',   name: 'category/history', beforeEnter: [logged],  component: () => import('@/page/assets/page/history.vue')},
  //     ]
  // },
   
  // air
  //  {path: '/dict',                      name: 'dict',           beforeEnter: [logged,god],   component: () => import('@/page/dict/index.vue'),
  //   children: [
  //       // {path: ':title/:id',            name: 'dict/category',  beforeEnter: [logged,god],  component: () => import('@/page/dict/category.vue')},
  //       {path: 'brands',                name: 'dict/brands',    beforeEnter: [logged,god],  component: () => import('@/page/dict/brands.vue')},
  //       {path: 'categories',            name: 'dict/categories',beforeEnter: [logged,god],  component: () => import('@/page/dict/categories.vue')},
  //       {path: 'brand/:title/:id',      name: 'dict/brand',     beforeEnter: [logged,god],  component: () => import('@/page/dict/brand.vue')},
  //       {path: 'artists',               name: 'dict/artists',   beforeEnter: [logged,god],  component: () => import('@/page/dict/artists.vue')},
  //       {path: ':id',                   name: 'dict/page',      beforeEnter: [logged,god],  component: () => import('@/page/dict/dict.vue')},
        
  //   ]
  // },

  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

// router.beforeEach((to) => {
//   console.log('route',to)
//   // const db = Store()
//   // if (to.meta.requiresAuth && !store.profile.id) return '/welcome'
//   // next()
// })


function notlogged(to: any, from: any, next: any) {
  const db = Store()
  const profile: any = db.profile
  if (!profile.id){
    next()
  } else{
    next({ name: 'profile'})
  }
}


function logged(to: any, from: any, next: any) {
  const db = Store()
  const profile: any = db.profile
  if (profile.id){
    next()
  } else{
    next({ name: 'home' })
  }
}

function god(to: any, from: any, next: any) {
  const db = Store()
  // const profile: any = db.profile
  if (db.god){
    next()
  } else{
    next({ name: 'home' })
  }
}


export default router
